import React, { Component } from 'react';
import styled from 'styled-components';
import PathPrefix from '../../Utils/PathPrefixUtil';
import LangSwitcher from '../LangSwitcher';
import * as Widget from '../Widget';
import Link from '../Link';
import * as L from '../../Utils/Lang';

class Footer extends Component {
  render() {
    let { extraCss = '' } = this.props;

    return (
      <Wrapper extraCss={extraCss}>
        <div className="info">
          <div className="content">

            <div className="container-logo">
              <img src="/images/navbar/tel25-logo.png" style={{ width: 128 }} />
            </div>

            <div className="container-contact">
              <p>+886-2-25211005</p>
              <p>台北市中山區民權東路二段148號4樓</p>
              <p>info@tel25.com</p>
            </div>

            <div className="container">
              <Link className="link" to="/faq">
                {L.s('faq')}
              </Link>
              <Link className="link" to="/privacy_policy">
                {L.s('privacy_policy')}
              </Link>
              <Link className="link" to="/terms_of_service">
                {L.s('terms_of_service')}
              </Link>
            </div>

            <div className="container">
              <p>FOLLOW US</p>
              <div className="socials">
                <a
                  className="social-item"
                  target="_blank"
                  href="https://www.facebook.com/tel25limited/"
                >
                  <Widget.BgImage
                    extraCss="background-color: inherit;"
                    image={PathPrefix('/images/redesign/icon01.png')}
                  />
                </a>

                <a
                  className="social-item"
                  target="_blank"
                  href="https://www.instagram.com/tel25limited/"
                >
                  <Widget.BgImage
                    extraCss="background-color: inherit;"
                    image={PathPrefix('/images/redesign/icon02.png')}
                  />
                </a>

                <a
                  className="social-item"
                  target="_blank"
                  href="https://line.me/R/ti/p/%40nzr0588p"
                >
                  <Widget.BgImage
                    extraCss="background-color: inherit;"
                    image={PathPrefix('/images/redesign/icon03.png')}
                  />
                </a>
              </div>
              <LangSwitcher extraCss="margin-top: 30px;" />
            </div>
          </div>
        </div>

        <div className="copyright">
          Copyright © 2018 TEL25 Limited. All rights reserved.
        </div>
      </Wrapper>
    );
  }
}

let Wrapper = styled.div`
  background: #d8d8d8;
  color: white;
  font-size: 18px;
  text-align: justify;

  & > .info {
    max-width: 1220px;
    margin: 0 auto;
    padding: 100px 25px 65px;

    & > .content {
      width: 100%;
      display: flex;
      justify-content: space-between;

      & > .container-logo {
        max-width: 178px;
        width: 100%;
      }

      & > .container-contact {
        margin-bottom: 45px;
        max-width: 407px;
        width: 100%;
        font-size: 14px;
        color: #575757;
        line-height: 30px;
      }

      & > .container {
        width: 292.5px;
        font-size: 14px;
        color: #575757;
        line-height: 30px;
      }

      & .link {
        display: block;
        color: inherit;
        text-decoration: none;
      }

      & .socials {
        display: flex;
        margin-top: 20px;
      }

      & .social-item {
        width: 35px;
        height: 35px;
        margin-left: 10px;
      }

      & .social-item:first-child {
        margin-left: 0;
      }
    }
  }

  & > .copyright {
    padding-bottom: 50px;
    text-align: center;
    font-weight: 500;
    font-size: 10px;
    color: #575757;
  }

  @media screen and (max-width: 600px) {
    & > .info {
      & > .content {
        flex-wrap: wrap;
        justify-content: flex-start;
      }
    }
  }

  @media screen and (max-width: 600px) {
    font-size: 16px;

    & > .info {
      & > .content {
        & .social-item {
          width: 35px;
          height: 35px;
          margin-right: 10px;
        }
      }
    }
  }
`;

export default Footer;
