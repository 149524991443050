import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as L from '../../Utils/Lang';
import PathPrefix from '../../Utils/PathPrefixUtil';
import Selectors from '../../Selectors';
import ActionCreator from '../../ActionCreator';
import Link from '../Link';
import * as Icon from '../Icon';
import * as Widget from '../Widget';

const Breakpoint = 1024;

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rwdMenuOpen: false,
    };
  }

  render() {
    let { navbarHeight, profile, itemsInCart } = this.props;
    let { rwdMenuOpen } = this.state;

    return (
      <Navbar.Wrapper
        navbarHeight={navbarHeight}
      >
        <Navbar.RowWrapper navbarHeight={navbarHeight}>
          <Link className="logo" to="/">
            <Widget.BgImage
              image={PathPrefix('/images/navbar/tel25-logo.png')}
              color="transparent"
            />
          </Link>

          <div className="navs">
            <div className="nav-menus">
              <Link
                to="/products"
                className={`nav-item ${
                  this._isActiveRoute('/products') ? 'active' : ''
                }`}
              >
                {L.s(`products`)}
              </Link>

              <Link
                to="/data_products"
                className={`nav-item ${
                  this._isActiveRoute('/data_products') ? 'active' : ''
                }`}
              >
                {L.s(`data-products`)}
              </Link>

              <Link
                to="/voice"
                className={`nav-item ${
                  this._isActiveRoute('/voice') ? 'active' : ''
                }`}
              >
                {L.s(`voice`)}
              </Link>

              <Link
                to="/about_us"
                className={`nav-item ${
                  this._isActiveRoute('/about_us') ? 'active' : ''
                }`}
              >
                {L.s`about-tel25`}
              </Link>

              <Link
                to="/me"
                className={`nav-item ${
                  this._isActiveRoute('/me') ? 'active' : ''
                }`}
              >
                {L.s(`member`)}
              </Link>

              <Link
                to="/checkout"
                className="nav-item-cart"
              >
                <Icon.Cart
                  color="white"
                  style={{ width: 20, height: 20, marginRight: 5 }}
                />
                <span className="text">Cart</span>
                <div className="amount">
                  <span className="number">{profile ? itemsInCart : 0}</span>
                </div>
              </Link>
            </div>

            <div className="rwd-menu-toggle">
              <Link className="cart" to="/checkout">
                <Icon.Cart color={'#0075C1'} style={{ marginRight: 5 }} />
                <div className="amount">{profile ? itemsInCart : 0}</div>
              </Link>
              <Widget.IconButton
                onClick={() => this.setState({ rwdMenuOpen: true })}
              >
                <Icon.Menu color={'#0075C1'} />
              </Widget.IconButton>
            </div>
          </div>
        </Navbar.RowWrapper>

        <Navbar.RwdMenuWrapper
          open={rwdMenuOpen}
          onClick={() => this.setState({ rwdMenuOpen: false })}
        >
          <Navbar.RwdMenus
            open={rwdMenuOpen}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <Link to="/" className="menu-item">
              {L.s('home')}
            </Link>

            <Link to="/products" className="menu-item">
              {L.s(`products`)}
            </Link>

            <Link to="/data_products" className="menu-item">
              {L.s(`data-products`)}
            </Link>

            <Link to="/voice" className="menu-item">
              {L.s(`voice`)}
            </Link>

            <Link to="/about_us" className="menu-item">
              {L.s`about-tel25`}
            </Link>

            <Link to="/me" className="menu-item">
              {L.s(`member`)}
            </Link>

            <Link
                to="/checkout"
                className="nav-item-cart"
            >
                <Icon.Cart
                  color="white"
                  style={{ width: 20, height: 20, marginRight: 5 }}
                />
                <span className="text">Cart</span>
                <div className="amount">
                  <span className="number">{profile ? itemsInCart : 0}</span>
                </div>
            </Link>

            <Widget.Button
              label={L.s(`close`)}
              labelStyle={{ color: '#0075C1' }}
              style={{ width: '100px', margin: '100px 25px 0px' }}
              onClick={() => this.setState({ rwdMenuOpen: false })}
            />
          </Navbar.RwdMenus>
        </Navbar.RwdMenuWrapper>
      </Navbar.Wrapper>
    );
  }

  _isActiveRoute = routeRegexp => {
    let { pathname } = this.props;

    if (routeRegexp === '/') {
      return ['/', '/tw/', '/en/'].some(p => p === pathname);
    }

    return !!pathname.match(routeRegexp);
  };
}

Navbar.Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: white;
  background-image: linear-gradient(#808080b0, rgba(0, 0, 0, 0));
  background-repeat: no-repeat;
  background-position: ${props =>
    props.isNavbarTransparent ? '0 0' : `0 -${props.navbarHeight}px`};
  padding: 0px 25px;
  z-index: 10;
  transition: background 200ms ease-in-out;
`;

Navbar.RowWrapper = styled.div`
  margin: 0 auto;
  max-width: 1220px;
  height: ${props => props.navbarHeight}px;
  display: flex;
  align-items: center;

  & > .logo {
    width: 154px;
    height: 68px;
  }

  & > .navs {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & > .nav-menus {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      & > .nav-item {
        margin-right: 25px;
        padding: 5px 11px;
        font-size: 16px;
        color: #0075C1;
        text-decoration: none;
      }

      & > .active {
        border-bottom: 1px solid #0A7AC3;
      }

      & > .nav-item-cart {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-left: 10px;
        text-decoration: none;
        background: #0075C1;
        padding: 10px 15px;

        & > .text {
          margin-right: 10px;
          font-size: 14px;
          color: white;
        }

        & > .amount {
          display: flex;
          align-items: center;
          justify-content: center;

          padding: 0px 6px;
          min-width: 20px;
          height: 20px;
          border-radius: 50%;
          background: white;

          & > .number {
            color: #58A4EF;
            font-size: 14px;
          }
        }
      }

      @media screen and (max-width: ${Breakpoint}px) {
        display: none;
      }
    }

    & > .rwd-menu-toggle {
      display: flex;
      align-items: center;

      & > .cart {
        display: flex;
        align-items: center;
        margin-right: 10px;
        text-decoration: none;
        cursor: pointer;

        & > .amount {
          display: flex;
          align-items: center;
          justify-content: center;

          border-radius: 50%;
          min-width: 2-px;
          height: 20px;
          padding: 9px 6px;
          background-color: #0075C1;
          font-size: 12px;
          color: white;
        }
      }

      @media screen and (min-width: ${Breakpoint + 1}px) {
        display: none;
      }
    }

  }

  @media screen and (max-width: 375px) {
    & > .logo {
      width: 120px;
      height: 53px;
    }
  }
`;

Navbar.RwdMenuWrapper = styled.div`
  position: fixed;
  top: 0;
  left: ${props => (props.open ? '0' : '-100vw')};
  width: 100vw;
  height: 100vh;
  background-color: ${props =>
    props.open ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0)'};
  transition: background-color 250ms linear,
    left 0ms linear ${props => (props.open ? '0ms' : '250ms')};
`;

Navbar.RwdMenus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 25px;
  width: 240px;
  height: 100%;
  background-color: white;
  transform: ${props => (props.open ? 'none' : 'translate(-240px, 0px)')};
  transition: transform 250ms linear;

  & > .menu-item {
    margin-left: 20px;
    padding: 10px;
    font-size: 16px;
    color: #0075C1;
    text-decoration: none;
    cursor: pointer;
  }

  & > .nav-item-cart {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 20px;
        margin-left: 20px;
        min-width: 114px;
        text-decoration: none;
        background: #0075C1;
        padding: 10px 15px;

        & > .text {
          margin-right: 10px;
          font-size: 14px;
          color: white;
        }

        & > .amount {
          display: flex;
          align-items: center;
          justify-content: center;

          padding: 0px 6px;
          min-width: 20px;
          height: 20px;
          border-radius: 50%;
          background: white;

          & > .number {
            color: #58A4EF;
            font-size: 14px;
          }
        }
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
    itemsInCart: (() => {
      let total = 0;
      let items = Selectors.cart.getData(state).items;

      for (let id in items) {
        total += items[id].configs.length;
      }

      return total
    })()
  }),
  ActionCreator
)(Navbar);
