import React from 'react';
import styled from 'styled-components';
import {
  CircularProgress,
  RaisedButton,
  FlatButton,
  IconButton,
  DropDownMenu,
  MenuItem,
  RadioButton,
  Checkbox,
} from 'material-ui';

export const BgImage = styled.div`
  background-color: ${props => props.color || '#d0d0d0'};
  background-image: url(${props => props.image});
  background-size: ${props => props.size || 'cover'};
  background-repeat: ${props => props.repeat || 'no-repeat'};
  background-position: ${props => props.position || 'center'};
  width: 100%;
  height: 100%;
  ${props => props.extraCss};
`;

export const Container = styled.div`
  ${props => props.extraCss};
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${props => props.extraCss};
`;

export const MaxWidth = styled.div`
  width: ${props => props.width}px;
  max-width: 100%;
  margin: ${props => props.margin || '0 auto'};
  ${props => props.extraCss};
`;

export const Row = styled.div`
  display: flex;
  align-items: ${props => props.align || 'stretch'};
  justify-content: ${props => props.justify || 'flex-start'};
  flex-wrap: ${props => (props.wrap ? 'wrap' : 'nowrap')};
  ${props => props.extraCss};
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.align || 'stretch'};
  justify-content: ${props => props.justify || 'flex-start'};
  ${props => props.extraCss};
`;

export const BaseButton = styled.button`
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
  }

  ${props => props.extraStyle};
`;

export const BlueButton = styled(BaseButton)`
  border-radius: 7px;
  padding: 8px 31px 7px;
  background: #3f8dcf;
  box-shadow: 0 2px 4px 0 rgba(0, 135, 241, 0.5);
  color: #fff;
  font-size: 18px;
  font-weight: 600;

  ${props => props.extraCss};
`;

export const BasicButton = styled.button`
  min-width: 116px;
  box-shadow: 0 2px 6px 0 rgba(29,64,117,0.50);
  border: 0px;
  padding: 10px;
  background: #0075C1;
  font-size: 14px;
  color: #FFFFFF;
  cursor: pointer;

  :focus {
    outline: none;
  }

  ${props => props.extraCss};
`;

export const SimpleInput = styled.input`
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  padding: 2px;
  background-color: #fff;
  color: #343434;
  font-size: 16px;
  text-align: ${props => props.align || 'center'};

  ${props => props.extraCss};
`;

export const Input = styled.input`
  padding: 5px;
  font-size: 16px;
  width: 100%;
  outline: none;
  border: 1px solid #ddd;

  &:focus {
    outline: 1px solid #06bcd5;
    box-shadow: ${props => (props.inset ? 'inset' : '')} 0 0 0px 2px
      rgba(6, 188, 213, 0.5);
    outline-offset: -1px;
  }

  ::placeholder {
    font-size: 16px;
    color: #D5D5D5;
    opacity: 1; /* Firefox */
  }

  ${props => props.extraCss};
`;

export const TextArea = styled.textarea`
  padding: 5px;
  font-size: 16px;
  width: 100%;
  outline: none;
  border: 1px solid #ddd;

  &:focus {
    outline: 1px solid #06bcd5;
    box-shadow: ${props => (props.inset ? 'inset' : '')} 0 0 0px 2px
      rgba(6, 188, 213, 0.5);
    outline-offset: -1px;
  }

  ${props => props.extraCss};
`;

export const FixRatioImage = ({
  image,
  width = '100%',
  heightRatio = '56.25%',
  color,
  extraStyle,
}) => (
    //heightRatio: (9: 16 = 56.25%) (3 : 4 = 75%)
    <div style={{ width: width, ...extraStyle }}>
      <div style={{ position: 'relative', top: 0, paddingTop: heightRatio }}>
        <BgImage
          image={image}
          color={color}
          extraCss="position: absolute; top: 0px;"
        />
      </div>
    </div>
  );

export const Select = styled.select`
  border-radius: 0;
  border: 1px solid #ddd;
  background-color: white;
  width: 100%;
  height: 30px;
  font-size: 16px;

  &:focus {
    outline: 2px solid #06bcd5;
    box-shadow: 0 0 0 0px 2px rgba(6, 188, 213, 0.5);
    outline-offset: -2px;
  }

  ${props => props.extraCss};
`;

export const SimpleSelect = styled.select`
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  background-color: #fff;
  color: #343434;
  font-size: 16px;
  text-align-last: ${props => props.align || 'center'};

  ${props => props.extraCss};
`;

export class QuantityInput extends React.Component {
  render() {
    let { value, onChange, extraCss } = this.props;

    return (
      <QuantityInput.Wrapper>
        <QuantityInput.Select value={value} onChange={onChange} extraCss={extraCss}>
          {Array.apply(null, { length: 20 }).map((n, index) => (
            <option key={index + 1} value={index + 1}>{index + 1}</option>
          ))}
        </QuantityInput.Select>
      </QuantityInput.Wrapper>
    );
  }

  static Select = styled.select`
    width: 100%;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #e9e9e9;
    background-color: #fff;
    color: #343434;
    font-size: 16px;
    text-align-last: ${props => props.align || 'center'};

    ${props => props.extraCss};
  `;

  static Wrapper = styled.div`
    display: flex;
    align-items: center;
  `;
}

export class NumberInput extends React.Component {
  render() {
    let { value } = this.props;

    return (
      <NumberInput.Wrapper>
        <div className="button minus" onClick={() => this._onClick('minus')}>
          -
        </div>
        <div className="value">{isNaN(parseFloat(value)) ? '' : value}</div>
        <div className="button plus" onClick={() => this._onClick('plus')}>
          +
        </div>
      </NumberInput.Wrapper>
    );
  }

  _onClick = type => {
    let { max, min, value, onChange } = this.props;
    let temp;
    value = parseFloat(value);
    value = isNaN(value) ? 0 : value;
    min = parseFloat(min);
    max = parseFloat(max);

    if (type === 'minus') {
      temp = value - 1;
      if (isNaN(min) || (!isNaN(min) && temp >= min)) {
        value = temp;
      }
    } else if (type === 'plus') {
      temp = value + 1;
      if (isNaN(max) || (!isNaN(max) && temp <= max)) {
        value = temp;
      }
    }

    onChange &&
      onChange({
        target: { value: String(value) },
      });
  };

  static Wrapper = styled.div`
    border-radius: 5px;
    border: 1px solid #e9e9e9;
    background: #ffffff;
    color: #343434;
    font-size: 16px;
    display: flex;
    align-items: center;

    & > .button {
      padding: 4px 10px;
      cursor: pointer;
    }

    & > .minus {
      border-right: 1px solid #e9e9e9;
    }

    & > .plus {
      border-left: 1px solid #e9e9e9;
    }

    & > .value {
      flex-grow: 1;
      text-align: center;
    }
  `;
}

export const MuiSelect = props => {
  return (
    <DropDownMenu
      {...props}
      onChange={(event, index, value) => {
        // to fit in unstated form usage
        console.log(value);
        props.onChange({ ...event, target: { value: value } });
      }}
    >
      {props.children}
    </DropDownMenu>
  );
};

export const CheckboxField = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 10px;
  width: 100%;

  & > .label-text {
    margin-left: 6px;
  }

  & > .airport-label-text {
    margin-left: 6px;
    flex: 1;
  }

  & .icons {
    margin-left: 20px;
    max-width: 200px;

    & > .credit-card {
      width: 50px;
    }
  }

  & .single-icon-container {
    margin-left: 20px;
    min-width: 180px;
  }

  & .cvs {
      width: 35px;

      @media screen and (max-width: 375px){
        width: 40px;

      }

      @media screen and (max-width: 360px){
        width: 35px;
      }
  }
`;

export const Spinner = CircularProgress;
export const Button = RaisedButton;
export { FlatButton, IconButton, MenuItem, RadioButton, Checkbox };
