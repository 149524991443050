import ApiProxy from '../ApiProxy';
import AppConfig from '../../src/Config'
import Actions from '../Reducers/Actions';

export default function CartService(config={}) {
    const name = 'cart';
    const actions = {
        CART_SET_CART: `${name}_SET_CART`,
        CART_ADD_ITEM_CONFIG: `${name}_ADD_ITEM_CONFIG`,
        CART_SET_ITEM_CONFIG: `${name}_SET_ITEM_CONFIG`,
        CART_DEL_ITEM_CONFIG: `${name}_DEL_ITEM_CONFIG`,
        CART_CLEAR: `${name}_CLEAR_CART`,
    };
    const initialState = () => ({
        hash: null,
        data: { items: {} },
    });
    const STORAGE_ID = 'cart';

    function itemReducer(state=undefined, {type, payload={}}) {
        let item = state;
        let {id, data: config} = payload;

        switch (type) {
            case actions.CART_ADD_ITEM_CONFIG: {
                if (!item) {
                    item = { id, configs: [] };
                }

                return {
                    ...item,
                    configs: [...item.configs, config]
                };
            }

            case actions.CART_SET_ITEM_CONFIG:
            case actions.CART_DEL_ITEM_CONFIG: {
                let configs = [...item.configs];
                let configIdx = configs.findIndex(conf => conf.tid === config.tid);
                if (configIdx === -1) {
                    return item;
                }

                if (type === actions.CART_SET_ITEM_CONFIG) {
                    configs.splice(configIdx, 1, config);
                } else if (type === actions.CART_DEL_ITEM_CONFIG) {
                    configs.splice(configIdx, 1);
                }

                if (configs.length === 0) {
                    // this means we should remove this in upper layer
                    return undefined;
                }

                return {
                    ...item,
                    configs
                }
            }
        }

        return state;
    }

    function reducer(state = fromStorage(), { type, payload }) {
        switch (type) {
            case actions.CART_ADD_ITEM_CONFIG:
            case actions.CART_SET_ITEM_CONFIG:
            case actions.CART_DEL_ITEM_CONFIG:
                try {
                    let nextItem = itemReducer(state.data.items[payload.id], {type, payload});
                    if (nextItem !== undefined) {
                        return {
                            ...state,
                            data: {
                                ...state.data,
                                items: {
                                    ...state.data.items,
                                    [payload.id]: nextItem
                                }
                            }
                        }
                    } else {
                        let itemsClone = {...state.data.items};
                        delete itemsClone[payload.id];

                        return {
                            ...state,
                            data: {
                                ...state.data,
                                items: itemsClone
                            }
                        }
                    }
                } catch (err) {
                    console.error(err);
                    return state;
                }

           case actions.CART_SET_CART:
                return {
                    ...state,
                    ...payload
                }

            case Actions.LOGOUT:
                return fromStorage();
        }

        return state;
    }

    async function pricePerConfig({id, data}) {
        return ApiProxy.post({
            secure: true,
            path: `/api/price/${id}/`,
            data: {
                data
            },
        });
    }

    async function preCheckout(data) {
        return ApiProxy.post({
            secure: true,
            path: AppConfig.favor === 'tel25' ? `/api/pre_checkout/` : `/api/sp/pre_checkout/`,
            data: {
                data,
            },
        });
    }

    async function checkout({ meta, hash }) {
        return ApiProxy.post({
            secure: true,
            path: AppConfig.favor === 'tel25' ? `/api/checkout/` : `/api/sp/checkout/`,
            data: {
                meta,
                hash,
            },
        });
    }

    return {
        name,
        actions,
        reducer,
        actionCreator: (store) => ({
            getPrice: ({ id, data }) => {
                return pricePerConfig({id, data: JSON.stringify(data)});
            },

            configItem: (action, { id, data }) => {
                let type = null;
                if (action === 'add') {
                    type = actions.CART_ADD_ITEM_CONFIG;
                } else if (action === 'set') {
                    type = actions.CART_SET_ITEM_CONFIG;
                } else if (action === 'del') {
                    type = actions.CART_DEL_ITEM_CONFIG;
                }

                if (!type) {
                    return Promise.reject('no such action');
                }

                if (action === 'add') {
                    data = {
                        ...data,
                        tid: new Date().getTime()  //only used in frontend
                    }
                }

                let currCartState = store.getState()[name];
                console.log('should show next item config data', data)
                let nextCartState = reducer(currCartState, {
                    type, payload: { id, data }
                });

                if (Object.keys(nextCartState.data.items).length === 0) {
                    return new Promise(resolve => {
                        store.dispatch({type: actions.CART_SET_CART, payload: initialState()})
                        toStorage(store.getState()[name]);
                        resolve()
                    })
                }

                console.log('nextCartState', nextCartState.data)
                return preCheckout(JSON.stringify(nextCartState.data))
                    .then(result => {
                        let {hash, meta} = result;
                        store.dispatch({
                            type: actions.CART_SET_CART,
                            payload: {hash, data: JSON.parse(meta)}
                        });
                        toStorage(store.getState()[name]);
                    })
                    .catch(err => {
                        console.warn(err);
                        throw err;
                    })
            },

            setData: (data, updateHash) => {
                let mergedData = {
                    ...store.getState()[name].data,
                    ...data
                };

                if (!updateHash) {
                    return new Promise(resolve => {
                        store.dispatch({type: actions.CART_SET_CART, payload: {data: mergedData}})
                        toStorage(store.getState()[name]);
                        resolve()
                    })
                }

                return preCheckout(JSON.stringify(mergedData))
                    .then(result => {
                        let {hash, meta} = result;
                        store.dispatch({
                            type: actions.CART_SET_CART,
                            payload: {hash, data: JSON.parse(meta)}
                        });
                        toStorage(store.getState()[name]);
                    })
            },

            checkout: (data) => {
                let mergedData = {
                    ...store.getState()[name].data,
                    ...data
                }

                return preCheckout(JSON.stringify(mergedData))
                    .then(result => {
                        let {hash, meta} = result;
                        return checkout({hash, meta})
                    })
                    .then(order => {
                        store.dispatch({
                            type: actions.CART_SET_CART,
                            payload: initialState()
                        });
                        toStorage(store.getState()[name]);
                        return order;
                    })
                    .catch(err => {
                        console.warn(err);
                        throw err;
                    })
            }
        }),

        selectors: {
            getItem: (state, id) => {
                return state[name].data.items[id] || null;
            },

            getData: (state) => {
                return state[name].data;
            },
        }
    };

    function fromStorage() {
        let cart = initialState();

        try {
          cart = JSON.parse(window.localStorage.getItem(STORAGE_ID));
          if (!cart) {
              cart = initialState();
          }
        } catch (err) {
          console.warn(err);
        }

        return cart;
    }

    function toStorage(state) {
        window.localStorage.setItem(STORAGE_ID, JSON.stringify(state));
    }
}
