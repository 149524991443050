export default function(state = {}, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export function byId(state, id) {
  return state.map[id];
}

export function all(state) {
  return state.list.filter(p => !p.is_single_page_item);
}

export function spAll(state) {
  return state.list.filter(p => p.is_single_page_item)
}