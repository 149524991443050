import * as _User from './Reducers/User';
import * as _Lang from './Reducers/Lang';
import * as _Order from './Reducers/Order';
import * as _Product from './Reducers/Product'
import * as _Home from './Reducers/Home';
import { namedExportWithMemorizedOnce } from './Utils/FpUtil';
import ParseQuery from './Utils/ParseQuery';
import {getSelectors} from './Services'

let User = namedExportWithMemorizedOnce(_User);
let Lang = namedExportWithMemorizedOnce(_Lang);
let Order = namedExportWithMemorizedOnce(_Order);
let Product = namedExportWithMemorizedOnce(_Product);
let Home = namedExportWithMemorizedOnce(_Home);

const selectors = {
  getQueryParams: ownProps => ParseQuery(ownProps.location.search),
  getLang: state => Lang.getLang(state.lang),
  getLoginUser: state => User.getLoginUser(state.user),
  getOrders: state => Order.getOrderList(state.order),
  getOrder: (state, id) => Order.getOrderDetailById(state.order, id),
  getProduct: (state, id) => Product.byId(state.product, id),
  getProductList: (state) => Product.all(state.product),
  getSPProductList: (state) => Product.spAll(state.product),
  getHomeData: (state) => Home.getData(state.home),
  ...getSelectors(),
};

console.log(selectors);

export default selectors;
