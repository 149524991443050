import CartServiceFactory from './Cart'
import Config from '../Domain/constants'
import { namedExportWithMemorizedOnce } from '../Utils/FpUtil';

const Services = {
  cart: CartServiceFactory(Config.cart)
}

function getReducers() {
    return Object.keys(Services).reduce(
        (acc, serviceName) => {
            acc[serviceName] = Services[serviceName].reducer;
            return acc;
        }, {}
    )
}

function getActionCreators(store) {
    return Object.keys(Services).reduce(
        (acc, serviceName) => {
            acc[serviceName] = Services[serviceName].actionCreator(store);
            return acc;
        }, {}
    )
}

function getSelectors() {
    return Object.keys(Services).reduce(
        (acc, serviceName) => {
            let selectors = Services[serviceName].selectors;
            acc[serviceName] = namedExportWithMemorizedOnce(selectors);
            return acc;
        }, {}
    )
}

export {
    getReducers,
    getActionCreators,
    getSelectors,
}