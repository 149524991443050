import React from 'react';
import { Provider } from 'react-redux';
import Layout from '../Layout';
import { getStore } from '../Store';
import ActionCreator from '../ActionCreator';
import { dispatchRouteInitClientActions } from '../PageInitActions';

class Page extends React.Component {
  render() {
    return (
      <Provider store={this.store}>
        <Layout key={this.props.pageContext.lang} {...this.props}>
          {this.props.children}
        </Layout>
      </Provider>
    );
  }

  componentWillMount() {
    console.log('initialState', this.props.pageContext.initialState);
    this.store = getStore(this.props.pageContext.initialState);
    let appActions = ActionCreator(this.store.dispatch).appActions;
    appActions.setLang(this.props.pageContext.lang);
  }

  async componentDidMount() {
    await dispatchRouteInitClientActions({
      store: getStore(),
      location: this.props.location,
    });
  }
}

export function withPage(Comp) {
  class PageWrapper extends React.Component {
    render() {
      return (
        <Page {...this.props}>
          <Comp {...this.props}>{this.props.children}</Comp>
        </Page>
      );
    }
  }

  PageWrapper.displayName = 'Page-' + Comp.displayName;
  return PageWrapper;
}

export default Page;
