export const SupportLangs = [
  { display: '繁體中文', key: 'tw' },
  { display: 'English', key: 'en' },
];

export const DefaultLang = 'tw';

const StringTable = {
  tw: {
    welcome: '歡迎!',
    cancel: '取消',
    confirm: '確認',
    'all-fields-required': '所有欄位皆須填寫',
    register: '註冊',
    login: '登入',
    logout: '登出',
    'log-in-with-facebook': '以Facebook帳號登入',
    'log-in-with-google': '以Google帳號登入',
    username: '帳號',
    email: '電子信箱',
    password: '輸入密碼',
    'confirm-password': '確認密碼',
    'validation': '帳號驗證',
    'back-to-login': '回到登入',
    'confirm-password-again': '再次輸入密碼',
    'password-and-confirm-password-not-same': '密碼與確認密碼不同！',
    'check-email-format': '請確認電子信箱格式是否正確!',
    'must-have-username-and-password': '帳號及密碼為必填!',
    'error-login-message': '帳號密碼錯誤!',
    'username-used': '此帳號已被使用！',
    'email-used': '此電子信箱已被使用！',
    'error-register-message': '註冊失敗！',
    'error-message': '發生錯誤',
    'already-member': '已經有帳號了？',
    'not-member': '還沒有帳號？',
    'forget-password': '忘記密碼？',
    'email-not-found': '此電子信箱不存在！',
    'error-reset-password-message': '重設密碼失敗！',
    'reset-password': '重設密碼',
    'back-to': '回到',
    'reset-password-success-message': '新密碼已寄至您的電子信箱。',
    'reset-validation': '重寄認證信',
    'validation-has-sended-message': '驗證信已寄出',
    'reset-validation-message':
      '您的帳號已註冊成功，\n但尚未通過認證。\n請收取認證信並點選連結。',
    'reset-validation-success-message': '認證信已寄出！',
    'err-reset-validation-message': '寄件失敗！',
    'validation-success': '你的帳號已被啟用',
    'page-expired': '此網頁已過期',
    'back-home': '回首頁',
    'payment-price': '付款金額',
    'payment-success': '已付款',
    'payment-failure': '付款失敗',
    'payment-waiting': '尚未付款',
    'payment-refunded': '已退款',
    'payment-form_generation': '尚未付款',
    'payment-processing': '付款中',
    'payment-type-ecpay-sp': '線上刷卡',
    'payment-type-ecpay_cvpay-sp': '超商代碼繳費',
    'payment-type-ecpay_cvs-sp': '貨到付款',
    'payment-type-ecpay': '線上刷卡/超商代碼繳費',
    'payment-type-ecpay_cvs': '貨到付款',
    'payment-type-paypal': 'PAYPAL',
    'payment-type-offline': '銀行轉帳',
    'login-first': '請先登入！',
    'welcome-to-tel25': '歡迎來到TEL25官網會員中心',
    'login-to-tailor-made': '快來登入訂製一張量身打造的國際上網卡吧！',
    'create-sim-card': '製作網卡',
    'no-order-so-far': 'OOPS! 目前沒有任何訂單記錄',
    'no-order-or-no-right-access-order': '無此訂單或您無權限瀏覽此訂單',
    'product-info': '商品資料',
    data: '流量',
    day: '天',
    days: '天數',
    quantity: '數量',
    subtotal: '小計',
    'cancel-edit': '放棄修改',
    'confirm-edit': '確認修改',
    delete: '移除',
    'add-to-cart-success': '成功加入購物車!',
    'continue-buy': '繼續選購',
    'go-to-checkout': '前往結帳',
    'calculate-price': '計算價錢',
    'buy-now': '立即購買',
    'add-to-cart': '加入購物車',
    'please-login-first': '請先登入！',
    cart: '購物車',
    'cart-empty': '你尚未選取任何商品',
    'products-total': '商品小計',
    total: '合計',
    shipping: '運費',
    'confirm-order': '確認訂單',
    next: '下一步',
    back: '上一步',
    'order-item': '訂單商品',
    product: '商品',
    price: '金額',
    'checkout-info': '結帳資料',
    recipient: '收件人資訊',
    name: `姓名`,
    phone: '聯絡電話',
    address: '地址',
    'update-buyer-info': '同步更新購買人資料',
    'remember-my-info': '記住上方收件人資訊，下次購買時自動填入',
    payment: '選擇付款方式',
    method: '方式',
    'credit-card-online': '線上刷卡',
    'cv-pay': '超商代碼繳費',
    'store-pickup': '超商貨到付款',
    'select-store': '選取門市',
    'reselect-store': '重選門市',
    'store-number': '門市店號：',
    'store-name': '門市名稱：',
    'payment-method': '付款方式',
    'delivery-location': '取貨地點',
    'check-order-details': '查看訂單明細',
    'close-order-details': '關閉訂單明細',
    'payment-failure-message': '付款失敗！請 聯絡客服 或 重新下訂！',
    submit: '送出',
    'submit-success': '送出成功！',
    'submit-failure': '送出失敗！請聯絡客服！',
    'contact-us': '聯絡我們',
    message: '意見',
    'enter-valid-email': '請輸入正確的email',
    'update-failure-message': '更新失敗！請再試一次或聯絡網站人員。',
    'update-success': '更新成功！',
    update: '更新',
    home: '首頁',
    products: '漫遊自由配',
    'data-products': '數據產品',
    voice: '語音服務',
    'about-tel25': '關於TEL25',
    member: '會員中心',
    'email-note': '請務必填寫可正常收發信件之電子郵件，以便客服人員聯繫',
    type: '問題類別',
    'order-problem': '訂單相關問題',
    'product-problem': '產品相關問題',
    'voice-problem': '語音相關問題',
    'member-problem': '會員相關問題',
    'other-problem': '其他',
    checkout: '前往結帳',
    'get-cvpay-code': '前往獲得超商代碼',
    'from-price-tw': '起',
    'from-price-en': '',
    faq: '常見問題',
    privacy_policy: '隱私權政策',
    terms_of_service: '使用者條款',
    steps: '使用說明',
    close: '關閉',
    'change-password': '更新密碼',
    'old-password': '舊密碼',
    'new-password': '新密碼',
    'confirm-new-password': '確認新密碼',
    'change-password-success': '密碼更新成功！',
    'old-password-incorrect': '舊密碼錯誤！',
    'new-and-confirm-password-not-same': '新密碼和確認新密碼要一致喔！',
    profile: '基本資料',
    'order-list': '訂單記錄',
    'recaptcha-error': 'reCAPTCHA驗證失敗！請再試一次。',
    'choose-delivery-location': '選擇取貨地點',
    'view-store-location': '查看取貨地點',
    'choose-airport': '選擇機場',
    location: '地點',
    'delivery-location-airport': '機場',
    'delivery-location-convenience_store-sp': '7-11超商取貨',
    'delivery-location-convenience_store': '7-11',
    'airport-TSA': '台北松山-統振櫃檯',
    'airport-TPE-T1': '桃園一航-多圓發櫃檯',
    'airport-TPE-T2': '桃園二航-多圓發櫃檯',
    'airport-KHH': '高雄小港-統振櫃檯',
    'pickup-time':'取貨時間',
    'payment-delivery-location-note':
      '註：若為機場取貨，請務必選擇付款方式為「線上刷卡」',
    'only-open-online-credit-card': '註：目前僅開放線上刷卡及超商貨到付款功能',
    'form-error-email': '請輸入正確的email',
    'form-error-phone':
      '請輸入正確的手機號碼！格式為：十碼數字，且為09開頭，不含標點符號等。例：0987654321',
    'form-error-name':
      '姓名格式不符！長度為全中文2-5字，或全英文4-10字母，不含標點符號等',
    'form-error-tax-id': '統編格式錯誤！須為八碼數字。',
    partners: '合作夥伴',
    'shipping-fee-notice': '超商取貨需支付運費',
    'deposit-card-number': '加值卡號',
    'deposit-card-number-enter-hint': '請輸入欲加值之卡號或電話號碼 (必填)',
    'deposit-product-enter-hint': '加值商品必須填入卡號！',
    'normal-products': '固定產品',
    'physical-channel': '實體通路',
  },
  en: {
    welcome: 'Welcome!',
    cancel: 'Cancel',
    confirm: 'Confirm',
    'all-fields-required': 'All fields are required.',
    register: 'Register',
    login: 'Login',
    logout: 'Logout',
    'log-in-with-facebook': 'Log in with Facebook',
    'log-in-with-google': 'Log in with Google',
    username: 'Username',
    email: 'Email',
    password: 'Password',
    'back-to-login': 'Back to Login',
    'confirm-password-again': 'Confirm Password Again',
    'validation': 'Validation',
    'password-and-confirm-password-not-same': "Password and confirm password aren't same！",
    'check-email-format': 'Please check email format!!',
    'confirm-password': 'Confirm password',
    'must-have-username-and-password':
      'Please enter both username and password!',
    'error-login-message': 'Fail to login!',
    'error-register-message': 'Fail to register!',
    'username-used': 'The username is already used!',
    'email-used': 'The email is already used!',
    'error-message': 'Error happen',
    'already-member': 'Already a member?',
    'not-member': 'Not a member?',
    'forget-password': 'Forgot Password?',
    'email-not-found': "Can't find this email.",
    'error-reset-password-message': 'Fail to reset password!',
    'reset-password': 'Reset Password',
    'back-to': 'Back to',
    'reset-password-success-message':
      'Your new password has been sent to your email.',
    'reset-validation': 'Resend Validation Email',
    'validation-has-sended-message': 'The validation email has been sended.',
    'reset-validation-message':
      'Your account is registered but not validated.\nPlease receive the validation email and click the link.',
    'reset-validation-success-message': 'The validation email is sent!',
    'err-reset-validation-message': 'Fail to send email!',
    'validation-success': 'Your account has been activated.',
    'page-expired': 'This page has expired.',
    'back-home': 'back home',
    'payment-price': 'Price',
    'payment-success': 'Success',
    'payment-failure': 'Failure',
    'payment-waiting': 'Waiting',
    'payment-form_generation': 'Waiting',
    'payment-processing': 'Processing',
    'payment-type-ecpay-sp': 'Credit Card',
    'payment-type-ecpay_cvpay-sp': 'CV Pay',
    'payment-type-ecpay_cvs-sp': 'Cash on delivery',
    'payment-type-ecpay': 'Credit Card/CV Pay',
    'payment-type-ecpay_cvs': 'Cash on delivery',
    'payment-type-paypal': 'PAYPAL',
    'payment-type-offline': 'ATM',
    'login-first': 'Please login first!',
    'welcome-to-tel25': 'Welcome to tel25 official member center.',
    'login-to-tailor-made': 'Login to custom your tailor-made SIM card.',
    'create-sim-card': 'Create SIM Card',
    'no-order-so-far': 'OOPS! There aren\'t any orders so far.',
    'no-order-or-no-right-access-order':
      'This order does not exist or you have no right to access this order.',
    'product-info': 'Product Info',
    data: 'Data',
    day: 'day',
    days: 'Days',
    quantity: 'Quantity',
    subtotal: 'Subtotal',
    'cancel-edit': 'Cancel',
    'confirm-edit': 'Confirm',
    delete: 'Delete',
    'add-to-cart-success': 'Successfully added to your cart!',
    'continue-buy': 'Continue',
    'go-to-checkout': 'Go to checkout',
    'calculate-price': 'Calculate price',
    'buy-now': 'Buy now',
    'add-to-cart': 'Add to cart',
    'please-login-first': 'Please login first!',
    cart: 'Cart',
    'cart-empty': 'Cart is empty!',
    'products-total': 'Products',
    total: 'Total',
    shipping: 'Shipping',
    'confirm-order': 'Confirm',
    next: 'Next',
    back: 'Back',
    'order-item': 'Order item',
    product: 'Product',
    price: 'Price',
    'checkout-info': 'Checkout information',
    recipient: 'Recipient',
    name: 'Name',
    phone: 'Phone',
    address: 'Address',
    'update-buyer-info': 'Update buyer information',
    payment: 'Payment',
    method: 'Method',
    'credit-card-online': 'Credit card online',
    'cv-pay': 'CV Pay',
    'store-pickup': 'Convenience store pickup',
    'select-store': 'Select',
    'reselect-store': 'Reselect',
    'store-number': 'Store number:',
    'store-name': 'Store name:',
    'payment-method': 'Payment method',
    'delivery-location': 'Delivery location',
    'check-order-details': 'Check order details + ',
    'close-order-details': 'Close order details - ',
    'payment-failure-message':
      'Payment failure！Please contact customer service or reorder！',
    submit: 'submit',
    'submit-success': 'Succeeded in submitting!',
    'submit-failure': 'Failed to submit! Please contact our customer service.',
    'contact-us': 'Contact Us',
    message: 'Message',
    'enter-valid-email': 'Please enter a valid email address.',
    'update-failure-message':
      'Update failure！Please try again later or contact staff.',
    'update-success': 'Update Success！',
    update: 'Update',
    home: 'Home',
    products: 'Products',
    'data-products': 'Data Products',
    voice: 'Voice',
    'about-tel25': 'About TEL25',
    member: 'Member',
    'email-note':
      'Please write correct email. Staff will reply as soon as possible.',
    type: 'Type of Problem',
    'order-problem': 'Order',
    'product-problem': 'Product',
    'voice-problem': 'Voice',
    'member-problem': 'Member',
    'other-problem': 'Other',
    checkout: 'Checkout',
    'get-cvpay-code': 'Get CV Pay code',
    'from-price-tw': '',
    'from-price-en': 'From ',
    faq: 'FAQ',
    privacy_policy: 'Privacy Policy',
    terms_of_service: 'Terms of Service',
    steps: 'Product Instructions',
    close: 'CLOSE',
    'change-password': 'Change password',
    'old-password': 'Old password',
    'new-password': 'New password',
    'confirm-new-password': 'Confirm new password',
    'change-password-success': 'Password changed Successfully！',
    'old-password-incorrect': 'The old password is incorrect.',
    'new-and-confirm-password-not-same':
      "Password confirmation doesn't match password.",
    profile: 'Profile',
    'order-list': 'Order list',
    'recaptcha-error': 'reCAPTCHA verification failed, please try again.',
    'delivery-location': 'Delivery Location',
    'view-store-location': 'View Pick-up location',
    'choose-airport': 'choose airport',
    location: 'location',
    'delivery-location-airport': 'Airport',
    'delivery-location-convenience_store-sp': 'ConvenienceStore',
    'delivery-location-convenience_store': 'ConvenienceStore',
    'airport-TSA': 'TSA',
    'airport-TPE-T1': 'TPE-T1',
    'airport-TPE-T2': 'TPE-T2',
    'airport-KHH': 'KHH',
    'pickup-time':'pickup time',
    'payment-delivery-location-note':
      'Note: Please choose for "Credit Card" when you need to get your sim at airports',
    'only-open-online-credit-card': 'Note: Currently only open credit card online and convenience store pickup.',
    'form-error-email': 'email format error, please check your email',
    'form-error-phone':
      'Phone format error, please input 10 numbers, starts with 09, without special characters, ex: 0912345678',
    'form-error-name':
      'Name format error, please input 4-10 english characters, without special characters',
    'form-error-tax-id': 'tax id incorrect, should be 8 numbers',
    partners: 'Partners',
    'shipping-fee-notice':
      'convenience store will charge additional shipping-fee',
    'deposit-card-number': 'Deposit card number',
    'deposit-card-number-enter-hint': 'Please enter deposit card number or phone(required)',
    'deposit-product-enter-hint': 'Deposit products are required to enter card number!',
    'normal-products': 'Normal Products',
    'physical-channel': 'Physical Channel'
  },
};

export default StringTable;
