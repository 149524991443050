import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Selectors from '../Selectors';

const LinkWithLangPrefix = props => {
  let { className, extraCss, children, to, lang } = props;

  return (
    <LinkStyled className={className} extraCss={extraCss} to={`/${lang}${to}`}>
      {children}
    </LinkStyled>
  );
};

const LinkStyled = styled(Link)`
  ${props => props.extraCss};
`;

export default connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state),
  }),
  null
)(LinkWithLangPrefix);
