import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Selectors from '../Selectors';
import ActionCreator from '../ActionCreator';
import { SupportLangs } from '../StringTable';
import * as Icon from './Icon';

class LangSwitcher extends Component {
  constructor() {
    super();
  }

  render() {
    let { appActions, lang, extraCss } = this.props;

    return (
     <LangSwitcher.Wrapper extraCss={extraCss}>
      <select
        value={lang}
        onChange={(event) => {
            appActions.setLang(event.target.value);
            this._pushCurrentLocation();
        }}
      >
        {SupportLangs.map(l => (
            <option
              key={l.key}
              value={l.key}
            >
                {l.display}
            </option>
          ))
        }
      </select>
      <div className='icon'>
        <Icon.ArrowDropDown
          color={'#575757'}
        />
      </div>
     </LangSwitcher.Wrapper>
    );
  }

  _pushCurrentLocation = () => {
    let { navActions } = this.props;

    if (typeof window === 'undefined') {
      return;
    } else {
      let pathname = window.location.pathname;

      if (SupportLangs.some(l => l.key === pathname.substr(1, 2))) {
        navActions.push(pathname.substr(3));
      } else {
        navActions.push(pathname);
      }
    }
  };

  static Wrapper = styled.div`
    position: relative;
    width: 140px;
    height: 40px;
    display: flex;
    align-items: center;

    & > select {
      width: 100%;
      height: 100%;
      border-radius: 0px;
      border: 1px solid #575757;
      padding: 5px 20px;
      background: #d8d8d8;
      color: #575757;
      font-size: 14px;
      font-weight: 500;

      /*remove default appearance*/
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      &::-ms-expand {
        display: none;
      }
      
      &:focus {
        outline: none;
      }
      
      & option {
        color: black;
        background: white;
      }       
    }

    & > .icon {
      position: absolute;
      top: 0;
      right: 0;
      width: 43px;
      height: 40px;
      border: 1px solid #575757;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
    }
    
    ${props => props.extraCss}
  `;
}

export default connect(
  (state, ownProps) => ({
    lang: Selectors.getLang(state),
  }),
  ActionCreator
)(LangSwitcher);
